/*
 * Author: Roberto Torres
 * Company: Softech Corporation
 * Version: 1.0.0
 * Date: 2021-08-30
 * 
 * Description: 
 * Class to facilitate log of errors
 * 
 */

import { _st } from '@/softech';
import { createErrorLog } from '@/graphql/mutations';
import { getErrorLog } from '@/graphql/queries';
import { v4 as uuidv4 } from 'uuid';
import store from '@/store';
import { API, graphqlOperation } from 'aws-amplify';

export class PetuErrorLog {
    constructor() {
        this.data = {
            id                      : '',
            method                  : '',
            error                   : '',
            stackTrace              : '',
            data                    : ''
        };
    }

    async logError({ method, error, stackTrace, data }) {
        try {
            let data = {
                id: uuidv4(),
                method,
                error,
                stackTrace: _st.isNUE( stackTrace ) ? '' : stackTrace,
                data: _st.isNUE( data ) ? '' : data
            }

            if( store.getters['auth/isLoggedIn'] === true )
                await API.graphql(graphqlOperation(createErrorLog, { input: data }));
            else
                await API.graphql( { query: createErrorLog, variables: { input: data }, authMode: 'API_KEY' } );
        }
        catch(error) {
            console.log(`ERROR LOGGING: ${error}`);
        }
    }
    async getError( errorId ) {
        try {
            const p = await API.graphql( graphqlOperation( getErrorLog, { id: errorId } ) );
            this.data = p.data.getErrorLog;
        }
        catch(error) {
            console.log(`ERROR GETTING LOG: ${error}`);
        }
    }
}
// timestamps.js
import { _st } from '@/softech'
import { PetuErrorLog } from '@/classes/log'
import { createTimestamp, updateTimestamp, deleteTimestamp } from '@/graphql/mutations'
import { getTimestamp } from '@/graphql/queries'
import { listTimestamps } from '@/graphql/customQueries'

import { API, graphqlOperation } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'

export class PetuTimestamp {
    constructor(timestamp = null) {
        this.data = _st.isNU(timestamp)
        ? { id: null, customerID: null, date: null, time: null }
        : timestamp
    }

    async load(timestampId = null) {
        if(_st.isEmpty(timestampId)) return
        const t = await API.graphql(graphqlOperation(getTimestamp, { id: timestampId }))
        this.data = t.data.getTimestamp
    }

    async getList({ filter, limit }) {
        try {
            let variables = {}
            if(!_st.isNUE(filter)) variables.filter = filter
            if(!_st.isNUE(limit)) variables.limit = limit

            let timestamps = _st.isNUE(variables)
            ? await API.graphql(graphqlOperation(listTimestamps))
            : await API.graphql(graphqlOperation(listTimestamps, variables))

            return timestamps.data.listTimestamps.items
        } catch(error) {
            await (new PetuErrorLog()).logError({ method: 'PetuTimestamp/getList', error, stackTrace: error.stack })
            return Promise.reject(error)
        }
    }

    async save() {
        delete this.data.createdAt
        delete this.data.updatedAt
        try {
            let filter = {
                and: [
                    { date: { eq: this.data.date }}, 
                    { customerID: { eq: this.data.customerID }}
                ]
            }
            let count = (await this.getList({ filter})).length
            if (_st.isNUE(this.data.id)) {
                this.data.id = uuidv4()
                const t = await API.graphql(graphqlOperation(createTimestamp, { input: this.data }))
                this.data = t.data.createTimestamp
            } else {
                await API.graphql(graphqlOperation(updateTimestamp, { input: this.data }))
            }

            return Promise.resolve(count)
        } catch (error) {
            await (new PetuErrorLog()).logError({method: 'PetuTimestamp/save', error, stackTrace: error.stack, data: JSON.stringify(this.data)})
            return Promise.reject(error)
        }
    }

    async delete() {
        try {
            await API.graphql(graphqlOperation(deleteTimestamp, { input: { id: this.data.id} }))
            return Promise.resolve('success')
        } catch(error) {
            await (new PetuErrorLog()).logError({method: 'PetuTimestamp/delete', error, stackTrace: error.stack, data: JSON.stringify( this.data )});
            return Promise.reject(error)
        }
    }
}
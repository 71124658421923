<template>
    <v-row style="height: 100vh">
        <div class="background"></div>
        <v-col class="content">
            <v-card class="time-card" rounded="xl">
                <div class="pin-left">
                    <v-btn to="/secure/dashboard" large icon><v-icon>mdi-arrow-left-circle</v-icon></v-btn>
                </div>
                <v-card-title class="justify-center">
                    <router-link to="/secure/dashboard" class="d-flex align-center flex-column link">
                        <v-img src="@/assets/logo.png" width="125"></v-img>
                        <h3 class="black--text">Petu Punch</h3>
                    </router-link>
                </v-card-title>
                <v-card-subtitle class="text-center pb-0">{{ date }} @ {{ time }}</v-card-subtitle>
                <v-card-text>
                    <v-form v-model="valid" @submit.prevent="submit" ref="form">
                        <v-text-field v-model="pin" placeholder="Pin" ref="pin" dense :rules="pinRules" required clearable></v-text-field>
                        <v-btn :loading="loading" type="submit" color="primary" class="text-none mt-1" dark depressed rounded block>Ponchar</v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
        <div class="copyrights">© Petu Power {{ year }}. All rights reserved.</div>
    </v-row>
</template>

<script>
import { _st } from '@/softech'
import { PetuTimestamp } from '@/classes/timestamp';
import { PetuCustomer } from '@/classes/customer'

export default {
  data: () => ({
		loading: false,
        valid: true,
        pin: '',
		date: '',
		time: '',

		options: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
        pinRules: [
            v => !!v || 'El pin es requerido',
            v => !!v && v.length <= 10 || 'El pin tiene que ser válido'
        ],
	}),
	created() {
		this.date = (new Date()).toLocaleDateString('es-PR', this.options)
		this.time = (new Date()).toLocaleTimeString('en-US')
		setInterval(() => {
			this.date = (new Date()).toLocaleDateString('es-PR', this.options)
			this.time = (new Date()).toLocaleTimeString('en-US')
		}, 1000)
	},
    mounted() {
        this.$refs['pin'].$refs.input.focus()
    },
    methods: {
        /**
         *  get current date
         *	@return {String} - current date in Puerto Rico with ISO format
        */
        getCurrentDate :() => new Date().toLocaleString('sv', {timeZone: 'America/Puerto_Rico'}).substring(0, 10),
        async submit() {
            if (!this.valid || !await this.$store.getters['auth/isPetuPunchAdmin']) return
            try {
                this.loading = true

                let customer = new PetuCustomer()
                await customer.loadFromPin(this.pin)
                let customerID = customer.data.id

                if(!_st.isEmpty(customerID)) {
                    let timestamp = new PetuTimestamp({ customerID, date: this.getCurrentDate(), time: this.time })
                    let count = await timestamp.save()
                    let greeting = count % 2 === 0 ? 'Hola' : 'Adios'
                    let status = count % 2 === 0 ? 'success' : 'punch-out'
                    this.$root.$emit('alert', { status, message: `${greeting}, ${customer.data.firstName}` })
                    this.$refs.form.reset()
                    this.$refs['pin'].$refs.input.focus()
                } else {
                    this.$root.$emit('alert', { status: 'error', message: 'No se encontró usuario con ese pin.' })
                }
            } catch (err) {
                console.error(err)
               this.$root.$emit('alert', { status: 'error', message: 'Error al momento de someter ponche' })
            } finally {
                this.loading = false
            }
        }
    },
    computed: {
        year: () => (new Date()).getFullYear(),
    },
}
</script>
<style  scoped>
.content {
    height: 100vh;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.background {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(238,58,140);
    background: linear-gradient(187deg, rgba(238,58,140,1) 0%, rgba(246,137,38,1) 100%);
}
.time-card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
}

.time-card .v-card__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 30px; 
}

.copyrights {
    color: green;
    text-align: center;
    position: sticky;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    text-align: center;
}

.pin-left {
    padding: 10px;
    position: absolute;
    left: 0;
    top:0;
}
</style>
